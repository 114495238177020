import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AppNavbarModule } from "./navbar/navbar.module";
import { ShellComponent } from "./shell.component";
import { ProcessBarComponent } from "./process-bar/process-bar.component";
import { HrpOrganizationalUnitService } from "@features/administration/business";

@NgModule({
    imports: [CommonModule, AppNavbarModule, ProcessBarComponent, RouterModule],
    exports: [ShellComponent],
    declarations: [ShellComponent],
    providers: [HrpOrganizationalUnitService]
  })
  export class AppShellModule { }
