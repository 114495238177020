<header class="shell-header flex flex-col fixed top-0 left-0 right-0 z-10 h-24">
  <app-process-bar
    *ngIf="!responsiveService.$isMobile()"
    class="h-12 desktop:h-8"
  ></app-process-bar>
  <app-navbar class="flex-grow"></app-navbar>
</header>
<main
  class="overflow-y-auto overflow-x-hidden mt-24 bg-base text-base-content h-[calc(100vh-6rem)]"
>
  <router-outlet></router-outlet>
</main>

<app-landscape-warning
  [isLandscapeSmartphone]="this.responsiveService.isMobileLandscape$()"
></app-landscape-warning>
