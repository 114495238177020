import { Component, input } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-landscape-warning',
  templateUrl: './landscape-warning.component.html',
  styleUrl: './landscape-warning.component.scss',
  imports: [TranslocoPipe],
  standalone: true,
})
export class LandscapeWarningComponent {
  isLandscapeSmartphone = input(false);
}
