import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  OnDestroy,
  OnInit,
  signal,
  untracked,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { BehaviorSubject, Subject, firstValueFrom } from 'rxjs';
import { AppStore } from 'src/app/store/app/app.store';
import { NavbarItem } from '@core/defs';
import { Router } from '@angular/router';
import { Role, SessionService } from '@core/auth';
import { NavOverlayDirective } from './navbar-overlay.directive';
import { CdkAccordionItem } from '@angular/cdk/accordion';
import { ResponsiveService } from '@core/services';
import { AppAccountService } from '@features/account';

@Component({
  selector: 'app-navbar',
  templateUrl: 'navbar.component.html',
  styleUrls: ['navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppNavbarComponent implements OnInit, OnDestroy {
  private _onDestroy$ = new Subject();

  initials: string = '';
  activeItem$ = new BehaviorSubject<NavbarItem>({
    key: '',
    title: '',
    icon: '',
    routerLink: '',
  });

  @ViewChild(NavOverlayDirective) flyoverdirective: NavOverlayDirective;
  @ViewChild(CdkAccordionItem) planmenu: CdkAccordionItem;
  visible: { [key: string]: boolean }[] = [];
  $visible: WritableSignal<{ [key: string]: boolean }[]> = signal([]);

  navItems: NavbarItem[] = [
    // {
    //   key: 'dashboard',
    //   title: 'dashboard',
    //   icon: 'dashboard',
    //   routerLink: '/dashboard',
    //   onNavbarForBreakpoint: ['desktop', 'tablet', 'mobile'],
    //   notifications: true,
    //   position: 'left',
    //   click: () => this.setNavItemActive('dashboard'),
    // },
    {
      key: 'messages',
      i18n: 'messages',
      icon: 'chat',
      routerLink: '/messages',
      notVisibleForRole: {
        mobile: ['CMF_HRP_INFO', 'CMF_HRP_WORKS_COUNCIL', 'CMF_HRP_EMPLOYEE', 'HRP'],
        tablet: ['CMF_HRP_INFO', 'CMF_HRP_WORKS_COUNCIL', 'CMF_HRP_EMPLOYEE', 'HRP'],
        desktop: ['CMF_HRP_INFO', 'CMF_HRP_WORKS_COUNCIL', 'CMF_HRP_EMPLOYEE', 'HRP'],
      },
      onNavbarForBreakpoint: ['desktop', 'tablet', 'mobile'],
      notifications: true,
      position: 'left',
      click: () => this.setNavItemActive('messages'),
    },
    {
      key: 'my-calendar',
      i18n: 'my-calendar',
      icon: 'today',
      routerLink: '/my',
      notVisibleForRole: {
        mobile: ['CMF_HRP_INFO', 'CMF_HRP_WORKS_COUNCIL'],
        tablet: ['CMF_HRP_INFO', 'CMF_HRP_WORKS_COUNCIL'],
        desktop: ['CMF_HRP_INFO', 'CMF_HRP_WORKS_COUNCIL'],
      },
      onNavbarForBreakpoint: ['desktop', 'tablet'],
      click: () => this.setNavItemActive('my-calendar'),
      position: 'left',
    },
    {
      key: 'calendar-group',
      i18n: 'calendar-group',
      icon: 'calendar_month',
      routerLink: '/kalender/group',
      visibleForRole: [
        'HRP',
        'HRP-Administrator',
        'CMF_HRP_EMPLOYEE',
        'CMF_HRP_ADMIN',
        'CMF_HRP_INFO',
        'CMF_HRP_TECH_ADMIN',
      ],
      notVisibleForRole: {
        mobile: [],
        tablet: [],
        desktop: ['HRP-Planer', 'CMF_HRP_PLANNING'],
      },
      onNavbarForBreakpoint: ['desktop', 'tablet'],
      click: () => this.setNavItemActive('calendar-group'),
      position: 'left',
    },
    {
      key: 'calendar-planning',
      i18n: 'calendar-planning',
      icon: 'edit_calendar',
      routerLink: '/planning',
      onNavbarForBreakpoint: ['desktop'],
      hiddenMobile: true,
      visibleForRole: [
        'HRP-Administrator',
        'HRP-Planer',
        'CMF_HRP_ADMIN',
        'CMF_HRP_PLANNING',
        'CMF_HRP_TECH_ADMIN',
      ],
      click: () => this.setNavItemActive('calendar-planning'),
      position: 'left',
    },
    {
      key: 'schedule-audit',
      i18n: 'schedule-audit',
      icon: 'event_available',
      routerLink: '/audit',
      onNavbarForBreakpoint: ['desktop', 'tablet'],
      hiddenMobile: true,
      visibleForRole: ['CMF_HRP_WORKS_COUNCIL', 'CMF_HRP_TECH_ADMIN'],
      click: () => this.setNavItemActive('schedule-audit'),
      position: 'left',
    },
    {
      key: 'employee',
      i18n: 'employee',
      icon: 'groups',
      routerLink: '/mitarbeiter',
      visibleForRole: [
        'HRP-Administrator',
        'HRP-Planer',
        'CMF_HRP_ADMIN',
        'CMF_HRP_PLANNING',
        'CMF_HRP_TECH_ADMIN',
      ],
      onNavbarForBreakpoint: ['desktop'],
      position: 'left',
      click: () => {
        this.employeeClicked();
        this.setNavItemActive('employee');
      },
    },
    {
      key: 'me-employee',
      i18n: 'me-employee',
      icon: 'contact_page',
      routerLink: '/mitarbeiter/form/unlinked',
      onNavbarForBreakpoint: ['desktop', 'tablet'],
      visibleForRole: ['CMF_HRP_EMPLOYEE', 'HRP'],
      notVisibleForRole: {
        mobile: [
          'HRP-Planer',
          'HRP-Administrator',
          'Betriebsrat',
          'root',
          'CMF_HRP_ADMIN',
          'CMF_HRP_PLANNING',
          'CMF_HRP_WORKS_COUNCIL',
          'CMF_HRP_TECH_ADMIN',
          'CMF_HRP_INFO',
        ],
        tablet: [
          'HRP-Planer',
          'HRP-Administrator',
          'Betriebsrat',
          'root',
          'CMF_HRP_ADMIN',
          'CMF_HRP_PLANNING',
          'CMF_HRP_WORKS_COUNCIL',
          'CMF_HRP_TECH_ADMIN',
          'CMF_HRP_INFO',
        ],
        desktop: [
          'HRP-Planer',
          'HRP-Administrator',
          'Betriebsrat',
          'root',
          'CMF_HRP_ADMIN',
          'CMF_HRP_PLANNING',
          'CMF_HRP_WORKS_COUNCIL',
          'CMF_HRP_TECH_ADMIN',
          'CMF_HRP_INFO',
        ],
      },
      position: 'left',
      active: false,
      click: () => this.setNavItemActive('me-employee'),
    },
    {
      key: 'menu',
      i18n: 'menu',
      icon: 'menu',
      onNavbarForBreakpoint: ['mobile', 'tablet'],
      position: 'right',
      click: () => this.flyoverdirective.toggle(),
    },
    {
      key: 'administration',
      i18n: 'administration',
      icon: 'settings',
      routerLink: '/admin',
      position: 'right',
      visibleForRole: ['HRP-Administrator', 'CMF_HRP_ADMIN', 'CMF_HRP_TECH_ADMIN'],
      onNavbarForBreakpoint: ['desktop'],
      hiddenMobile: true,
      hiddenTablet: true,
      click: () => this.setNavItemActive('administration'),
    },
    {
      key: 'profile',
      title: '',
      icon: 'person',
      routerLink: '/account',
      position: 'right',
      onNavbarForBreakpoint: ['desktop'],
      rounded: true,
      click: () => this.setNavItemActive('profile'),
    },
    {
      key: 'logout',
      title: '',
      icon: 'logout',
      position: 'right',
      routerLink: '/logout',
      rounded: true,
      click: () => this.logout(),
    },
  ];

  get isMitarbeiter() {
    return (
      this.session.isMitarbeiter &&
      !this.session.isPlanner &&
      !this.session.isBetriebsrat &&
      !this.session.isAdministrator
    );
  }

  get roles(): Role[] {
    return this.session.roles;
  }

  myAccount$ = this._userAccount.myUserAccount;

  constructor(
    public store: AppStore,
    public responsive: ResponsiveService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    public session: SessionService,
    private _userAccount: AppAccountService,
  ) {
    effect(() => {
      const breakpoint = this.responsive.$breakpoint();

      if (breakpoint) {
        const routerLink = breakpoint === 'desktop' ? '/account/settings' : '/account/responsive';
        const navItem = this.getNavItemByKey('profile');
        if (navItem) {
          navItem.routerLink = routerLink;
        }

        this.cdr.markForCheck();
      }
    });
  }

  async ngOnInit() {
    try {
      const navItem = this.getNavItemByKey('profile');
      if (navItem) {
        const initials = await this.getInitials();
        navItem.title = initials;
        this.cdr.markForCheck();
      }
    } catch (err) {
      console.error('Error getting initials:', err);
    }

    if (this.isMitarbeiter) {
      this.setMyUserLink();
    }

    if (
      this.session.isAdministrator ||
      this.session.isPlanner ||
      this.session.isBetriebsrat ||
      this.session.isSuperAdministrator
    ) {
      this.setFirstOrganisationUnitLink();
    }

    this.checkActiveNavigationItem();
  }

  breakpointEffect = effect(() => {
    if (
      this.responsive.$isMobile() ||
      this.responsive.$isTablet() ||
      this.responsive.$isDesktop()
    ) {
      untracked(() => {
        for (let i = 0; i < this.navItems.length; i++) {
          this.checkVisibility(this.navItems[i]);
        }
      });
    }
  });

  async setMyUserLink() {
    try {
      const user = await firstValueFrom(this.session.user$);

      const navItem = this.getNavItemByKey('me-employee');

      if (navItem && user.uId) {
        navItem.routerLink = `/mitarbeiter/form/${user.uId}`;
      }

      this.cdr.markForCheck();
    } catch (err) {
      console.error('User is unlinked', err);
    }
  }

  async setFirstOrganisationUnitLink() {
    try {
      const navItem = this.getNavItemByKey('calendar-planning');
      if (navItem) {
        navItem.routerLink = '/planning';
      }

      this.cdr.markForCheck();
    } catch (err) {
      console.error('No Organisation Unit. User is unlinked', err);
    }
  }

  async getInitials(): Promise<string> {
    const initialsFromSession = sessionStorage.getItem('initials');
    let initials = initialsFromSession;

    if (initials) {
      return initials;
    } else {
      try {
        const user = await firstValueFrom(this.session.user$);
        initials = user.firstName.charAt(0) + user.lastName.charAt(0);
        sessionStorage.setItem('initials', initials);
        sessionStorage.setItem('me', user?.uId);
        return initials;
      } catch (err) {
        console.error('No initials. User is unlinked', err);
        return '';
      }
    }
  }

  handleClick(item: NavbarItem, event: Event) {
    event.stopPropagation();
    event.preventDefault();

    if (item.click && !item.expandable) {
      item.click();
    }
  }

  checkVisibility(item: NavbarItem) {
    if (
      item?.notVisibleForRole?.desktop ||
      item?.notVisibleForRole?.tablet ||
      item?.notVisibleForRole?.mobile
    ) {
      const shouldHide = item.notVisibleForRole[this.responsive.$breakpoint()].some((r) =>
        this.roles.includes(r),
      );
      this.$visible.set({ ...this.$visible(), [item.key]: !shouldHide });
      if (shouldHide) {
        return;
      }
    }

    if (item.visibleForRole?.length) {
      const shouldShow = item.visibleForRole.some((r) => this.roles.includes(r));
      this.$visible.set({ ...this.$visible(), [item.key]: shouldShow });
    } else {
      this.$visible.set({ ...this.$visible(), [item.key]: true });
    }

    if (this.$visible()[item.key] && item.children) {
      for (let i = 0; i < item.children.length; i++) {
        this.checkVisibility(item.children[i]);
      }
    }
  }

  checkActiveNavigationItem(): void {
    const isActiveItem = (routerLink: string): boolean => {
      if (routerLink) {
        const shortLink = routerLink.split('/').slice(0, 3).join('/');
        const shortUrl = this.router.url.split('?')[0];
        const segments = shortUrl.split('/');
        const result = segments.slice(0, 3).join('/');
        return result.includes(shortLink);
      }
      return false;
    };

    const checkChildren = (children: NavbarItem[]): boolean => {
      for (const child of children) {
        if (child.routerLink && this.router.url.includes(child.routerLink)) {
          return true;
        }
      }
      return false;
    };

    let activeItem = this.navItems.find((n) => {
      if (n.routerLink && n.routerLink.split('/').length > 2) {
        return isActiveItem(n.routerLink);
      } else if (n.children) {
        return checkChildren(n.children);
      } else if (n.routerLink) {
        return this.router.url.includes(n.routerLink);
      }
      return false;
    });

    if (activeItem && activeItem.children) {
      activeItem = activeItem.children.find((c) =>
        c.routerLink && c.routerLink.split('/').length > 2
          ? isActiveItem(c.routerLink)
          : this.router.url.includes(c.routerLink),
      );
    }

    if (activeItem) {
      this.activeItem$.next(activeItem);
      this.cdr.markForCheck();
    }
  }

  setNavItemActive(key: string) {
    let item = this.navItems?.find((n) => n.key === key);

    if (!item) {
      item = this.navItems.find((n) => n.children && n.children.some((c) => c.key === key));
      if (item) {
        item = item.children.find((c) => c.key === key);
      }
    }
    this.activeItem$.next(item);
  }

  //Klick auf "Mitarbeiter" setzt Filter und Cache zurück
  employeeClicked() {
    if (this.router.url.includes('/mitarbeiter/search')) {
      this.router
        .navigateByUrl('/', { skipLocationChange: true, replaceUrl: true })
        .then(() => this.router.navigate(['/mitarbeiter/search']));
    } else {
      this.router.navigate(['/mitarbeiter/search'], { replaceUrl: true });
    }
  }

  logout() {
    this.session.logout();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next({});
    this._onDestroy$.complete();
  }

  getNavItemByKey(key: string): NavbarItem | null {
    const index = this.navItems.findIndex((item) => item.key === key);

    if (index === -1) {
      return null;
    }
    return this.navItems[index];
  }
}
