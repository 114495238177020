import { MyAccountService } from '@swagger/humanresources';
import { Injectable } from '@angular/core';
import { AggregationTimespan } from '@swagger/humanresources';

@Injectable({ providedIn: 'root' })
export class MyAccountBusinessService {
  constructor(
    private readonly _myAccountService: MyAccountService
  ) {}

  getMyAccountWorkingSummary({
    scheduleUId,
    start,
    stop,
    aggregatedBy
  }: {
    scheduleUId: string;
    start: Date;
    stop: Date;
    aggregatedBy: AggregationTimespan
  }) {
    return this._myAccountService.myAccountGetMyWorkingSummary({
      scheduleUId,
      body: {
        start: start.toISOString(),
        stop: stop.toISOString(),
        aggregatedBy
      },
    });
  }
}
