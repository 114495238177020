import { ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { NavbarItem } from '@core/defs';
import { Router, RouterModule } from '@angular/router';

import { SessionService } from '@core/auth';
import { CdkAccordionItem, CdkAccordionModule } from '@angular/cdk/accordion';
import { NavbarService } from '@core/services';
import { AppNotificationsComponent } from '@shared/ui';
import { ResponsiveService } from '@core/services';
import { isEqual } from 'lodash';

@Component({
  selector: 'navbar-item',
  templateUrl: 'navbar-item.component.html',
  styleUrls: ['navbar-item.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    RouterModule,
    AppNotificationsComponent,
    CdkAccordionModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class AppNavbarItemComponent implements OnInit {
  @Input() item: NavbarItem = new NavbarItem();
  @Input() activeItem: NavbarItem = new NavbarItem();
  @ViewChild(CdkAccordionItem) planmenu: CdkAccordionItem;

  visible: boolean = true;

  breakpoint$ = this.responsive.breakpoint$;

  get mobile$() {
    return this.responsive.matchMobile$;
  }

  get tablet$() {
    return this.responsive.matchTablet$;
  }

  get desktop$() {
    return this.responsive.matchDesktop$;
  }

  get roles() {
    return this.session.roles;
  }

  constructor(
    private responsive: ResponsiveService,
    private session: SessionService,
    private elementRef: ElementRef,
    private navbar: NavbarService,
    public router :Router
  ) {}

  ngOnInit(): void {


    this.navbar.itemClicked$.subscribe(() => {
      if (this.planmenu?.expanded) {
        this.planmenu?.toggle();
      }
    });
  }

  onClick(event) {
    if (
      this.planmenu?.expanded &&
      !this.elementRef.nativeElement.contains(event.target)
    ) {
      this.planmenu?.toggle();
    }
  }

  handleNonExpandableItemClick() {
    this.navbar.notifyItemClicked();
  }

  checkItem(item: NavbarItem): boolean {
    if (
      item.children &&
      item.children.find((child) => child.key === this.activeItem.key)
    ) {
      return true;
    } else {
      return false;
    }
  }

  isActive():boolean {
    return this.activeItem.key === this.item.key;
  }
}
