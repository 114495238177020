import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { Action, ActionReducerMap, INIT, MetaReducer, META_REDUCERS, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { AppEffects } from './app/app.effects';
import { appReducer } from './app/app.reducer';
import { IAppState } from './app/app.state';
import { IProcessState, processReducer } from '@core/process';
import { AdministrationStoreModule } from 'src/features/administration/store/administration-store.module';
import { MessagesStoreModule } from '@features/messages/store';
import { CalendarStoreModule } from 'src/features/calendar/store/calendar-store.module';


export interface IState {
  app: IAppState;
  processes: IProcessState
}
export const reducers: ActionReducerMap<IState> = {
  app: appReducer,
  processes: processReducer
};
export const effects = [AppEffects];

function loadFromLocalStorage() {
  try {
    const localState = localStorage.getItem('appstate');
    if (localState) {
        return JSON.parse(localState);
    }
  } catch(err) {
    console.log('error loading state');
  }
  return {};
}

function saveToLocalStorage(state: any) {
  try {
    localStorage.setItem('appstate', JSON.stringify(state));
  } catch(err) {
    console.log('error saving state');
  }
}

function metaReducer(): MetaReducer<IState> {
  return (reducer) => (state, action: Action) => {
    if (action.type === INIT) {
      state = loadFromLocalStorage();
    }
    state = reducer(state, action) as IState;
    saveToLocalStorage(state);
    return state;
  };
}

@NgModule({
  imports: [
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    AdministrationStoreModule,
    CalendarStoreModule,
    MessagesStoreModule
  ],
  exports: [],
  providers: [
    // {
    //   provide: META_REDUCERS,
    //   useFactory: metaReducer,
    //   multi: true
    // },
  ],
})
export class AppStoreModule{}
