import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'page-not-found',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  host: { class: 'flex flex-col items-center justify-center w-full h-[75%]' },
  styles: ['pre { word-break: break-word; }'],
  template: `
    <div class="max-w-[75vw] px-8 py-2">
      <h1 class="flex flex-row gap-4 items-center justify-center hrp-text-h24-emph my-4">
        {{ 'error.page-not-found' | transloco }}
        <span class="material-symbols-rounded">sentiment_dissatisfied</span>
      </h1>

      <div class="grid grid-flow-col items-center justify-start gap-2 bg-hrp-error-100 p-2 w-[300px]">
        <span class="text-hrp-error-700 material-symbols-rounded">info</span>
        

        <pre
          *ngIf="router.url"
          class="text-hrp-error-500 whitespace-pre-wrap"
          >{{ router.url }}</pre
        >
      </div>

      <div class="flex flex-row justify-center gap-2 mt-8">
        <button class="primary accent" (click)="router.navigate(['./'])">
          {{ 'error.back-to-home' | transloco }}
        </button>
      </div>
    </div>
  `,
})
export class PageNotFoundComponent {
  constructor(public router: Router) {}
}
