import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ResponsiveService } from '@core/services';

@Component({
  selector: 'app-shell',
  templateUrl: 'shell.component.html',
  styleUrls: ['shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellComponent {
  responsiveService = inject(ResponsiveService);
}
