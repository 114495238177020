<ng-container *ngIf="item.expandable; else default">
  <cdk-accordion-item
    class="w-[12rem] flex flex-col relative z-[100] justify-between"
    #planmenu="cdkAccordionItem"
  >
    <button
      class="nav-item expandable h-12"
      [class.nav-item-mobile]="mobile$ | async"
      [class.nav-item-tablet]="tablet$ | async"
      [class.nav-item-desktop]="desktop$ | async"
      (click)="planmenu.toggle()"
    >
      <ng-template #defaultIcon>
        <span class="material-symbols-rounded mr-2" *ngIf="item.icon">{{
          item.icon
        }}</span>
      </ng-template>

      <span
        class="material-symbols-rounded burgermenufancy"
        *ngIf="checkItem(item) && activeItem.key !== ''; else defaultIcon"
        >{{ activeItem.icon }}</span
      >
      <p>{{ item.title | transloco }}</p>
      <span class="material-symbols-rounded">{{
        planmenu.expanded ? "expand_less" : "expand_more"
      }}</span>
    </button>

    <ng-container *ngIf="planmenu.expanded">
      <ng-content></ng-content>
    </ng-container>
  </cdk-accordion-item>
</ng-container>

<ng-template #default>
  <a
    class="h-12 icon-bold"
    [class.nav-item]="!item.rounded"
    [class.nav-item-account]="item.rounded"
    [class.nav-item-mobile]="
      (mobile$ | async) && !item.rounded
    "
    [class.nav-item-tablet]="
    (tablet$ | async) && !item.rounded
  "
    [class.nav-item-desktop]="
      (desktop$ | async) && !item.rounded
    "
    [class.active]="isActive()"
    [routerLink]="item.routerLink"
    (click)="handleNonExpandableItemClick()"
    ><ng-template #defaultIcon>
      <span
        class="material-symbols-rounded"
        *ngIf="(item.rounded && item.title === '') || !item.rounded"
        [class.icon-big]="item.rounded"
        [class.nav-icon]="!item.rounded"
        >{{ item.icon }}</span
      >
    </ng-template>
    <span
      class="material-symbols-rounded burgermenufancy"
      *ngIf="
        item.key === 'menu' &&
          activeItem.key !== '' &&
          !activeItem?.onNavbarForBreakpoint.includes(breakpoint$ | async);
        else defaultIcon
      "
      [class.icon-gap]="!item.rounded"
      >{{ activeItem.icon }}</span
    >
    @if (item.i18n) {
      {{ item.i18n | transloco }}
    } @else {
      {{ item.title }}
    }
    <div class="notification-wrapper isa-text-bold" *ngIf="item.notifications">
      <app-notifications
        [top]="'-2.25rem'"
        [right]="'-1.25rem'"
        [topTablet]="'-1.8rem'"
        [rightTablet]="'-1.25rem'"
        [topMobile]="'-2.25rem'"
        rightMobile="'-1.25rem'"
      ></app-notifications>
    </div>
  </a>
</ng-template>
