import { HttpInterceptorFn, provideHttpClient, withInterceptors } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Config } from '@paragondata/ngx-config';
import { authInterceptorFn } from '@core/auth';
import { ApiConfiguration } from '@swagger/humanresources';

export function createConfigurationFactory(name: string) {
  return function (config: Config): { rootUrl: string } {
    return config.get(`@openapi/${name}`);
  };
}

const serviceWorkerInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req.clone({ setHeaders: { 'ngsw-bypass': 'true' } }));
};

@NgModule({
  providers: [
    {
      provide: ApiConfiguration,
      useFactory: createConfigurationFactory('humanresources'),
      deps: [Config],
    },
    provideHttpClient(
      withInterceptors([authInterceptorFn, serviceWorkerInterceptor])
    )
  ],
})
export class OpenApiModule {}
