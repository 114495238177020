import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShellComponent } from './shell/shell.component';
import { AppShellModule } from './shell/shell.module';
import { AuthErrorComponent, RoleMap } from '@core/auth';
import { PageErrorComponent, PageNotFoundComponent } from '@shared/ui/error';
import {
  canActivateAdministration,
  canNotActivateWithRoles,
  isAuthenticatedGuard,
} from '../core/guards/role-based-access.guard';

const routes: Routes = [
  {
    path: 'login',
    children: [{ path: '**', redirectTo: 'my', pathMatch: 'full' }],
  },
  { path: 'auth-error', component: AuthErrorComponent },
  {
    path: '',
    component: ShellComponent,
    canActivate: [isAuthenticatedGuard],
    children: [
      {
        path: 'kalender',
        loadChildren: () => import('@features/calendar/pages').then((m) => m.routes),
      },
      {
        path: 'my',
        loadChildren: () => import('@features/my-plan/pages').then((m) => m.routes),
      },
      {
        path: 'admin',
        loadChildren: () => import('@features/administration/pages').then((m) => m.routes),
        canActivate: [canActivateAdministration],
      },
      {
        path: 'messages',
        loadChildren: () => import('@features/messages/pages').then((m) => m.routes),
        canActivate: [canNotActivateWithRoles(RoleMap.Employee)],
      },
      {
        path: 'audit',
        loadChildren: () => import('@features/audit/pages').then((m) => m.routes),
      },
      {
        path: 'mitarbeiter',
        loadChildren: () => import('@features/users/pages').then((m) => m.routes),
        canActivate: [canNotActivateWithRoles(RoleMap.Info)],
      },
      {
        path: 'account',
        loadChildren: () => import('@features/account/pages').then((m) => m.routes),
      },
      {
        path: 'planning',
        loadChildren: () => import('@features/planning/pages').then((m) => m.PlanningPageModule),
      },
      { path: '', redirectTo: 'my', pathMatch: 'full' },
      {
        path: 'error/:message/:redirectUrl',
        pathMatch: 'full',
        component: PageErrorComponent,
      },
      { path: '**', pathMatch: 'full', component: PageNotFoundComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' }), AppShellModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
