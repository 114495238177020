import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, NgModule } from '@angular/core';
import { __awaiter } from 'tslib';
import { ReplaySubject } from 'rxjs';
import * as i1 from '@angular/common/http';
const CORE_CONFIG_LOADER = new InjectionToken('core.config.loader');
const CORE_JSON_CONFIG_LOADER_URL = new InjectionToken('core.json.config.loader.url');

/**
 * Pick a value from an object at a given path.
 * @param path path of the value to pick
 * @param obj object to pick from
 * @returns the value at the path or undefined
 * @throws if obj is not an object
 */
function pick(path, obj) {
  const paths = path.split('.');
  // check if obj is null or undefined
  if (obj == null) {
    return undefined;
  }
  // check if obj is of type object and not an array
  // and throw an error if not
  if (typeof obj !== 'object' || Array.isArray(obj)) {
    throw new Error(`${obj} is not an object`);
  }
  let result = obj;
  // loop through the path and pick the value
  // early exit if the path is empty
  for (const path of paths) {
    result = result[path];
    if (result == null) {
      return undefined;
    }
  }
  return result;
}

// start:ng42.barrel
// end:ng42.barrel

class Config {
  constructor(_configLoader) {
    this._configLoader = _configLoader;
    this._initilized = new ReplaySubject(1);
  }
  get initialized() {
    return this._initilized.asObservable();
  }
  // load config and assign it to this._config
  init() {
    return __awaiter(this, void 0, void 0, function* () {
      this._config = yield this._configLoader.load();
      this._initilized.next();
    });
  }
  get(path) {
    return pick(path, this._config);
  }
}
Config.ɵfac = function Config_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || Config)(i0.ɵɵinject(CORE_CONFIG_LOADER));
};
Config.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: Config,
  factory: Config.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Config, [{
    type: Injectable
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [CORE_CONFIG_LOADER]
      }]
    }];
  }, null);
})();
function _initializeConfigFactory(config) {
  return () => config.init();
}
class ConfigModule {
  static forRoot(options) {
    const configLoaderProvider = {
      provide: CORE_CONFIG_LOADER,
      useClass: options.useConfigLoader
    };
    return {
      ngModule: ConfigModule,
      providers: [Config, configLoaderProvider, options.jsonConfigLoaderUrl ? {
        provide: CORE_JSON_CONFIG_LOADER_URL,
        useValue: options.jsonConfigLoaderUrl
      } : []]
    };
  }
}
ConfigModule.ɵfac = function ConfigModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ConfigModule)();
};
ConfigModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ConfigModule
});
ConfigModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConfigModule, [{
    type: NgModule,
    args: [{}]
  }], null, null);
})();
class JsonConfigLoader {
  constructor(url, http) {
    this.url = url;
    this.http = http;
  }
  load() {
    return this.http.get(this.url).toPromise();
  }
}
JsonConfigLoader.ɵfac = function JsonConfigLoader_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || JsonConfigLoader)(i0.ɵɵinject(CORE_JSON_CONFIG_LOADER_URL), i0.ɵɵinject(i1.HttpClient));
};
JsonConfigLoader.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: JsonConfigLoader,
  factory: JsonConfigLoader.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(JsonConfigLoader, [{
    type: Injectable
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [CORE_JSON_CONFIG_LOADER_URL]
      }]
    }, {
      type: i1.HttpClient
    }];
  }, null);
})();

// start:ng42.barrel
// end:ng42.barrel

// start:ng42.barrel
// end:ng42.barrel

/*
 * Public API Surface of ngx-config
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CORE_CONFIG_LOADER, CORE_JSON_CONFIG_LOADER_URL, Config, ConfigModule, JsonConfigLoader, _initializeConfigFactory, pick };
